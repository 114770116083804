import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Ace Advantage Tenis Kortlarını Keşfedin
			</title>
			<meta name={"description"} content={"Her Oyunun Destansı Bir Masal Olduğu Yer!"} />
			<meta property={"og:title"} content={"Hakkımızda | Ace Advantage Tenis Kortlarını Keşfedin"} />
			<meta property={"og:description"} content={"Her Oyunun Destansı Bir Masal Olduğu Yer!"} />
			<meta property={"og:image"} content={"https://turevoshot.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://turevoshot.com/img/3453453453.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://turevoshot.com/img/3453453453.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://turevoshot.com/img/3453453453.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://turevoshot.com/img/3453453453.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://turevoshot.com/img/3453453453.png"} />
			<meta name={"msapplication-TileImage"} content={"https://turevoshot.com/img/3453453453.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="space-between"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--grey"
						font="--base"
						letter-spacing="2px"
					>
						Bizim Sarayımız, Sizin Destanınız
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Hakkımızda
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					Ace Advantage Tenis Kortları sadece bir tesis değil, tenis ruhunun bir kutlamasıdır. Hikayemiz basit bir hayalle başladı: spor sevgisinin sınırları aştığı bir alan yaratmak. İlk servisin heyecanının, final noktasının yoğunluğunun ve oyunun neşesinin herkes tarafından yaşanabileceği ve tadına varılabileceği bir yer.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://turevoshot.com/img/4.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Şampiyonlar İçin Hazırlandı
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Burada her ayrıntı tenis deneyiminiz düşünülerek tasarlanmıştır. Titizlikle bakımı yapılan kortlardan keskin, beyaz çizgilere kadar her unsur tenisin hassasiyetine ve zarafetine bir övgü niteliğindedir. Kaliteye ve mükemmel bir oyun ortamına olan bağlılığımız, Ace Advantage'ı bu sporu soluyanlar için gidilecek yer haline getiriyor.
					</Text>
				</Box>
				<Image src="https://turevoshot.com/img/5.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://turevoshot.com/img/6.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						İmza Özellikleri
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Gece Maçları için Aydınlatma: Son teknoloji aydınlatma sistemlerimiz altında oyununuzu uzatın.
						Seyirci Dostu: Arkadaşlarınızın ve ailenizin izlemesi ve tezahürat yapması için davetkar alanlar.
						Ferahlık Köşesi: Çeşitli içeceklerle susuzluğunuzu giderin ve enerjinizi koruyun.

					</Text>
				</Box>
			</Box>
		</Section><Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Mirasa Katılın
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Ace Advantage'da sadece bir kort kiralamıyorsunuz; her atışa, her oyuna ve her oyuncuya değer veren bir topluluğun parçası oluyorsunuz. İster tenis yolculuğunuza başlıyor olun, ister mirasınızı şekillendirmek istiyor olun, kapılarımız size açık. Anlarınızı yaratın, zaferlerinizi kutlayın, anılarınızı oluşturun.
					</Text>
				</Box>
				<Image src="https://turevoshot.com/img/7.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://turevoshot.com/img/8.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Sizin Anınız Burada
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Sadece tenis oynamakla kalmayın, tenisi yaşayın. Ace Advantage parlamak için kortunuz, etkilemek için sahneniz, fethetmek için arenanız. Kortunuzu bugün ayırtın ve kendi tenis masalınızı yazmaya başlayın.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});